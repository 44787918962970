import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import {
  faChevronUp,
  faChevronDown,
  faUser,
  faCreditCard,
  faCheck,
  faPhone,
  faBarcode,
  faTimes,
  faFlagAlt,
  faUtensilsAlt,
  faMapMarkerAlt,
  faEnvelope,
  faSms,
  faGlobe,
  faConciergeBell,
  faCommentAltLines,
  faUsers,
  faClock,
  faBan,
  faMoneyBill,
  faSackDollar,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import "./CurrentList.scss";
import { Button } from "src/components/Button";
import { EmailSchedule } from "src/components/EmailSchedule";
import { Select as SelectForm } from "src/components/Form";
import { Flex } from "src/components/Layout";
import { getNameFromOrder } from "src/constants";
import { useForm } from "src/hooks";
import { ConfirmAlert } from "src/screens/shared";
import { genTaxRateForMealId } from "src/store/actions/pricing";
import { PhoneFormat, LOGGING, OrderMethods, PopUp } from "./";
import CartList from "./CartList";
import CheckoutList from "./CheckoutList";
import { DriverRoutesSummaryContainer } from "./DriverRoutesSummaryContainer";

export const ItemWithoutQuantity = ({
  item,
  numberOfItems,
  description,
  categoryName,
  name,
  price,
  specialInstructions,
  selectionItems,
  onCancelSelectionItem,
}) => {
  const [cancelSelectionItemFormId, setCancelSelectionItemFormId] =
    useState(null);

  const closeCancelSelectionItemForm = useCallback(
    () => setCancelSelectionItemFormId(null),
    []
  );

  return (
    <div className="item without-quantity">
      <div className="item-header">
        <span className="item-name">{` ${
          item !== undefined ? `#${item + 1} -  ` : ""
        }${name}`}</span>
        {price ? (
          <span className="item-price">{` $${price.toFixed(2)}`}</span>
        ) : null}
      </div>
      {description ? (
        <div className="item-description">
          <label>description</label>
          <span>{description}</span>
        </div>
      ) : null}
      {categoryName ? (
        <div className="item-description">
          <label>category</label>
          <span>{categoryName}</span>
        </div>
      ) : null}
      {selectionItems.length > 0 && (
        <div className="item-selections">
          <label>selections</label>
          {selectionItems.map((selectionItem, i) => {
            const cancelSelectionItem = () => {
              closeCancelSelectionItemForm();
              onCancelSelectionItem(
                selectionItem.goodSelectionId,
                selectionItem.goodSelectedItemId,
                1 // Cancel quantity
              );
            };

            return (
              <div key={selectionItem?.name} className="selection-item-wrapper">
                <span className="selection-item-name" style={{ flexGrow: 1 }}>
                  - {selectionItem?.name}
                </span>
                {selectionItem?.price ? (
                  <span className="selection-item-price">
                    ${selectionItem?.price?.toFixed(2)}
                  </span>
                ) : null}
                {onCancelSelectionItem != null &&
                selectionItem.isRequired === false ? (
                  <div style={{ alignSelf: "stretch", marginTop: "0" }}>
                    <button
                      style={{ marginLeft: "8px" }}
                      type="button"
                      onClick={() => {
                        setCancelSelectionItemFormId(selectionItem._id);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </button>
                  </div>
                ) : null}
                {cancelSelectionItemFormId === selectionItem._id && (
                  <div className="cancel-item-form">
                    <div className="field cancel-item-quantity">
                      <label>
                        Cancel <b>"{selectionItem?.name}"</b>
                      </label>
                    </div>
                    <button onClick={cancelSelectionItem}>Yes</button>
                    <button onClick={closeCancelSelectionItemForm}>No</button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {specialInstructions ? (
        <>
          <label>special instructions:</label>
          <span className="item-instructions">
            "{specialInstructions || ""}"
          </span>
        </>
      ) : null}
    </div>
  );
};

const MultiLineItem = ({
  dish,
  quantity,
  cancelQuantity,
  selections,
  specialInstructions,
  onCancelItem,
  price,
}) => {
  const {
    name,
    priceFoodieListed,
    selections: dishSelections,
    description,
    categoryName,
  } = dish;
  const [cancelItemFormIsOpen, setCancelItemFormIsOpen] = useState(false);
  const cancelItemInputRef = useRef();

  const maxCancelQuantity = quantity - (cancelQuantity || 0);

  const selectionItemCache = {};
  dishSelections?.forEach((selection) => {
    selection.items?.forEach((selectionItem) => {
      selectionItemCache[selectionItem._id] = selectionItem;
    });
  });

  const selectionItems = [];
  selections?.forEach((selection) => {
    selection.selectedItems?.forEach((selectionItem) => {
      if (
        selectionItem?.item?._id &&
        selectionItemCache[selectionItem?.item?._id]
      ) {
        selectionItems.push(selectionItemCache[selectionItem?.item?._id]);
      }
    });
  });

  const cancelItem = () => {
    const newCancelQuantity = Number(cancelItemInputRef.current?.value);
    if (
      isNaN(newCancelQuantity) ||
      newCancelQuantity <= 0 ||
      newCancelQuantity > maxCancelQuantity
    ) {
      toast.error("Invalid cancel quantity");
      return;
    }
    onCancelItem(newCancelQuantity);
  };

  return (
    <div className="item-wrapper">
      {Array(quantity - (cancelQuantity || 0)).fill(
        <ItemWithoutQuantity
          name={name}
          description={description}
          categoryName={categoryName}
          price={priceFoodieListed}
          specialInstructions={specialInstructions}
          selectionItems={selectionItems}
        />
      )}
      <div className="cancel-item">
        <div className="field cancel-item-toggle">
          <label>cancel</label>
          <button
            type="button"
            onClick={() => setCancelItemFormIsOpen(!cancelItemFormIsOpen)}
          >
            <FontAwesomeIcon
              icon={cancelItemFormIsOpen ? faChevronUp : faChevronDown}
            />
          </button>
        </div>
      </div>
      {cancelItemFormIsOpen && (
        <div className="cancel-item-form">
          <div className="field cancel-item-quantity">
            <label>quantity</label>
            <input
              ref={cancelItemInputRef}
              defaultValue={maxCancelQuantity}
              min="1"
              max={maxCancelQuantity}
              step="1"
              type="number"
            />
          </div>
          <button onClick={cancelItem}>cancel</button>
        </div>
      )}
    </div>
  );
};

const Order = ({
  order,
  index,
  total,
  onOrder,
  showDetails,
  onChangeOpsNote,
  onPayment,
  onCancelItem,
  onCancelOrder,
}) => {
  const [showCancelOrderConfirmation, setShowCancelOrderConfirmation] =
    useState(false);
  LOGGING && console.log("Order rendering with:", order);
  return (
    <div className="order">
      {/* <div
      className="order-driver"
      style={order.driver ? { background: order.driver.profileColor } : {}}
    >
      <span>driver:</span>
      <span>{order.driver ? order.driver.firstName : ""}</span>
    </div> */}
      <div className="order-name">
        {showDetails ? (
          <React.Fragment>
            <div className="field index">
              <FontAwesomeIcon icon={faFlagAlt} />
              <span>{`Person ${index + 1} of ${total}`}</span>
            </div>
            <div className="field person">
              {order.userGroup ? (
                <FontAwesomeIcon icon={faUsers} />
              ) : (
                <FontAwesomeIcon icon={faUser} />
              )}
              <span>
                <b>({order.orderHash})</b>{" "}
                {`${
                  order.name && order.name.trim().length > 0
                    ? order.name
                    : order?.user?.firstName +
                      order?.user?.lastName +
                      (order.userGroup ? order.userGroup.name : " ")
                }`}
              </span>
            </div>
            <div className="field phone">
              <FontAwesomeIcon icon={faPhone} />
              <span>{PhoneFormat(order.phone)}</span>
            </div>
            <div className="field order-id">
              <FontAwesomeIcon icon={faBarcode} />
              <span>{order._id}</span>
            </div>
            <div className="field order-total">
              <FontAwesomeIcon icon={faSackDollar} />
              <span>
                Order total (tax incl.):{" "}
                <b>${(order.sum + order.tax).toFixed(2)}</b>
              </span>
            </div>
            {/* <div className="field order-total-tip">
              <FontAwesomeIcon icon={faMoneyBill} />
              <span>
                Order total + tip: <b>${order.total.toFixed(2)}</b>
              </span>
            </div> */}
            <div className="field paid-time">
              <FontAwesomeIcon icon={faCreditCard} />
              <span>
                {order.payTime > 0
                  ? `Customer paid by ${
                      order.paidByPaypal ? "PayPal" : "card"
                    } at ${moment(order.payTime).format("h:mm A, ddd MMM DD")}.`
                  : "Not paid yet."}
              </span>
            </div>
            <div className="field note">
              <textarea
                defaultValue={order.opsNote}
                onChange={onChangeOpsNote.bind(this, order._id)}
                name="note"
                placeholder="Note"
              />
            </div>
            {order.orderTime && order.orderTime > 0 ? (
              <div className="field order-time">
                <FontAwesomeIcon icon={faCheck} />
                <span>{`${
                  order.orderOps ? `Ops ${order.orderOps.firstName}` : "We"
                } placed the order at ${moment(order.orderTime).format(
                  "h:mm A, ddd MMM DD"
                )}.`}</span>
              </div>
            ) : (
              <div className="action-wrapper">
                {order.payTime < 0 ? (
                  <button
                    className="mark-as-ordered"
                    onClick={onPayment.bind(this, order._id)}
                  >
                    {"Confirm Payment"}
                  </button>
                ) : (
                  <button
                    className="mark-as-ordered"
                    onClick={onOrder.bind(this, order._id)}
                  >
                    {"mark as ordered"}
                  </button>
                )}
              </div>
            )}
            {order.cancelTime && order.cancelTime > 0 ? (
              <div className="field cancel-time">
                <FontAwesomeIcon icon={faTimes} />
                <span>{`User cancelled the order at ${moment(
                  order.cancelTime
                ).format("h:mm A, ddd MMM DD")}.`}</span>
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <div className="field person-summary">
            <span>
              <b>({order.orderHash})</b> {getNameFromOrder(order)}
            </span>
            {/* <span>{`(Person ${index + 1} of ${total})`}</span> */}
          </div>
        )}
      </div>

      <div className="order-details">
        {Object.values(order.goods)
          // if sorted, the index will change
          // .sort((a, b) => (a.dish.name > b.dish.name ? 1 : -1))
          .map((g, i) => (
            <Fragment key={i}>
              {g.quantity > (g.cancelQuantity || 0) && (
                <MultiLineItem
                  key={i}
                  dish={g.dish}
                  price={g.price}
                  selections={g.selections}
                  quantity={g.quantity}
                  cancelQuantity={g.cancelQuantity}
                  specialInstructions={g.comment}
                  onCancelItem={(quantity) =>
                    onCancelItem(order._id, i, g.dish._id, quantity)
                  }
                />
              )}
            </Fragment>
          ))}
      </div>
      {showDetails ? (
        <Flex widthFull justify="center">
          {showCancelOrderConfirmation ? (
            <Flex direction="column" align="center">
              <span>Are you sure?</span>
              <Flex direction="row" justify="center">
                <Button
                  onClick={() => {
                    setShowCancelOrderConfirmation(false);
                    onCancelOrder(order._id);
                  }}
                  style={{ margin: "0px 16px" }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setShowCancelOrderConfirmation(false);
                  }}
                  style={{ margin: "0px 16px" }}
                >
                  No
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Button onClick={() => setShowCancelOrderConfirmation(true)}>
              Cancel Order
            </Button>
          )}
        </Flex>
      ) : null}
    </div>
  );
};

export const Summary = ({
  mealId,
  summary,
  totalItems,
  isPreOrdered,
  onSavePreOrderedDishQuantity,
  onCancelDish,
  total,
  markup,
  discount,
}) => {
  const { formData, onInputChange, setFormData } = useForm({});
  const [taxRate, setTaxRate] = useState(null);

  LOGGING &&
    console.log("Summary rendering with:", {
      mealId,
      summary,
      totalItems,
      isPreOrdered,
      formData,
      total,
      markup,
      discount,
    });
  useEffect(() => {
    let initFormData = {};
    Object.values(summary).forEach(({ dishId, preOrderedQuantity }) => {
      initFormData[dishId] = preOrderedQuantity;
    });
    setFormData(initFormData);
  }, [summary, setFormData]);

  const onMarkAllAsSoldOut = useCallback(() => {
    const preOrderedDishQuantity = {};

    LOGGING && console.log("onMarkAllAsSoldOut called with summary: ", summary);
    Object.values(summary).forEach(({ dishId, quantity }) => {
      preOrderedDishQuantity[dishId] = quantity;
    });
    onSavePreOrderedDishQuantity(mealId, preOrderedDishQuantity);
  }, [mealId, onSavePreOrderedDishQuantity, summary]);

  const onSave = useCallback(
    (targetDishId) => {
      const preOrderedDishQuantity = {};
      LOGGING &&
        console.log("onMarkAllAsSoldOut called with summary: ", {
          targetDishId,
          formData,
        });
      Object.entries(formData).forEach(([dishId, quantity]) => {
        if (dishId === targetDishId) {
          preOrderedDishQuantity[dishId] = Number(quantity);
        }
      });
      onSavePreOrderedDishQuantity(mealId, preOrderedDishQuantity);
    },
    [formData, mealId, onSavePreOrderedDishQuantity]
  );
  // Fetch tax
  useEffect(() => {
    setTaxRate(null);

    if (mealId == null) return;

    genTaxRateForMealId(mealId).then((tRate) => setTaxRate(tRate));
  }, [mealId]);

  const totalLessMarkup = total - markup;
  const totalWithDiscount = totalLessMarkup * (1 - discount);

  return (
    <div className="current-summary">
      {/* <div className="summary-title">summary</div> */}
      {Object.keys(summary)
        .sort((a, b) => (a > b ? 1 : -1))
        .map((item, index) => (
          <div className="item" key={index}>
            {console.log("summary is this: ", { summary, item })}
            <div className="item-name">{`${item}`}</div>
            <div className="item-quantity">
              <Flex align="center" gap={12}>
                <span>
                  $
                  {summary[item].priceFoodieRestaurant +
                    summary[item].totalSelectionPrice ||
                    summary[item].price ||
                    summary[item].priceFoodieListed +
                      summary[item].totalSelectionPrice}
                  x{summary[item].quantity}
                </span>
                {isPreOrdered && onSavePreOrderedDishQuantity && (
                  <>
                    <label>total:</label>
                    <input
                      className="item-pre-ordered-quantity"
                      name={summary[item].dishId}
                      value={formData[summary[item].dishId]}
                      onChange={onInputChange}
                    />
                    <Button
                      size="small"
                      onClick={() => onSave(summary[item].dishId)}
                    >
                      Save
                    </Button>
                  </>
                )}
                {onCancelDish && (
                  <Button
                    size="small"
                    variant="secondary"
                    onClick={() =>
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <ConfirmAlert
                              onConfirm={() =>
                                onCancelDish(mealId, summary[item].dishId)
                              }
                              onClose={onClose}
                              message={`Are you sure you want to cancel "${item}"?`}
                            />
                          );
                        },
                      })
                    }
                  >
                    Cancel
                  </Button>
                )}
              </Flex>
            </div>
          </div>
        ))}
      <div className="item total-items">
        <div className="item-name">items</div>
        <div className="item-quantity">{totalItems}</div>
      </div>
      <div className="item total-items">
        <div className="item-name">total</div>
        <div className="item-quantity">${total.toFixed(2)}</div>
      </div>
      {markup > 0 && (
        <div className="item total-items">
          <div className="item-name">markup</div>
          <div className="item-quantity">${markup.toFixed(2)}</div>
        </div>
      )}
      {markup > 0 && (
        <div className="item total-items">
          <div className="item-name">total less markup</div>
          <div className="item-quantity">${totalLessMarkup.toFixed(2)}</div>
        </div>
      )}
      <div className="item total-items">
        <div className="item-name">
          total with discount (-{discount * 100}%)
        </div>
        <div className="item-quantity">${totalWithDiscount.toFixed(2)}</div>
      </div>
      <div className="item total-items">
        <div className="item-name">due ({`+${taxRate * 100}% tax`})</div>
        <div className="item-quantity highlight">
          $
          {taxRate != null
            ? (totalWithDiscount * (1 + taxRate)).toFixed(2)
            : "N/A"}
        </div>
      </div>
      {isPreOrdered && (
        <div className="sold-out">
          <Button icon={faBan} onClick={onMarkAllAsSoldOut}>
            Mark All As Sold Out
          </Button>
        </div>
      )}
    </div>
  );
};

const Email = ({ orders, onEmail }) => {
  const ordersLeft = orders.filter(
    (order) => !order.hasOwnProperty("orderOps")
  );
  console.log(orders);
  return (
    orders.length > 0 &&
    orders[0].goods[0].dish.restaurant.orderMethod === 1 && (
      <div className="send-email">
        <button
          id="email"
          className="email"
          onClick={
            ordersLeft.length > 0 &&
            ordersLeft[0].goods[0].dish.restaurant.contact
              ? onEmail.bind(this, orders)
              : null
          }
        >
          {ordersLeft.length > 0 &&
          !ordersLeft[0].goods[0].dish.restaurant.contact
            ? "No email on file."
            : ordersLeft.length > 0
            ? "Click Here to Email Restaurant"
            : `All orders as of ${moment().format(
                "h:mm A, ddd MMM DD"
              )} have been sent!`}
        </button>
      </div>
    )
  );
};

const ChangeRestaurant = ({
  restaurantList,
  onChangeRestaurant,
  selectedRestaurant,
  showRestauntOptions,
  onToggleShowRestaurantOptions,
}) => {
  LOGGING &&
    console.log("ChangeRestaurant rendering with:", {
      restaurantList,
      showRestauntOptions,
      selectedRestaurant,
    });
  return (
    <div className="select-restaurant">
      <button
        className="view-mode selected"
        onClick={onToggleShowRestaurantOptions}
      >
        <span>{selectedRestaurant}</span>
        <FontAwesomeIcon
          icon={showRestauntOptions ? faChevronUp : faChevronDown}
        />
      </button>
      <PopUp
        isPoppedUp={showRestauntOptions}
        componentToDisplay={
          <ul className="view-restaurant-selections">
            {restaurantList.map((restaurant, index) => {
              const check = selectedRestaurant[0] === restaurant;
              return (
                <li key={index} className={check ? "selected" : ""}>
                  <button onClick={onChangeRestaurant.bind(this, index)}>
                    <span>{restaurant}</span>
                    {check ? <FontAwesomeIcon icon={faCheck} /> : null}
                  </button>
                </li>
              );
            })}
          </ul>
        }
        hidePopUp={onToggleShowRestaurantOptions}
        backgroundColor="transparent"
      />
    </div>
  );
};

const CurrentList = ({
  orders,
  checkouts,
  carts,
  canceledOrders,
  drivers,
  pdfRef,
  onOrder,
  showMealType,
  showDetails,
  onSelectMeal,
  onShowDetails,
  lunchOrders,
  earlyDinnerOrders,
  dinnerOrders,
  onChangeOpsNote,
  onEmail,
  onPayment,
  onCancelItem,
  onCancelOrder,
  onSaveRestaurant,
  restaurantList,
  selectedRestaurant,
  onChangeRestaurant,
  showRestaurantOptions,
  onToggleShowRestaurantOptions,
  onSavePreOrderedDishQuantity,
  onCancelDish,
  onFinishCart,
  onFinishCheckout,
  onFinishCheckoutNoPayment,
  onResumeOrder,
  onResumeOrderNoPayment,
}) => {
  const [isEditRestaurant, setIsEditRestaurant] = useState(false);
  const { formData, setFormData, updateFormData, onInputChange } = useForm();

  LOGGING &&
    console.log("CurrentList rendering with:", {
      orders,
      checkouts,
      carts,
      canceledOrders,
      showRestaurantOptions,
      selectedRestaurant,
    });

  let restaurant = undefined;
  let meal = undefined;

  if (orders.length > 0) {
    restaurant = orders[0].goods[0].dish.restaurant;
    meal = orders[0].meal;
  }
  // const markup = orders.reduce((acc, order) => {
  //   const { goods } = order;
  //   const markup = goods.reduce((acc, good) => {
  //     const totalSelectionPrice = good.selections.reduce((acc, selection) => {
  //       return (
  //         acc +
  //         selection.selectedItems.reduce((acc, selectedItem) => {
  //           return acc + selectedItem.item?.price || 0;
  //         }, 0)
  //       );
  //     }, 0);
  //     const dishMarkup = good.dish?.priceFoodieRestaurant
  //       ? (good.price - good.dish.priceFoodieRestaurant) * good.quantity
  //       : 0;
  //     const selectionMarkup = good.selections.reduce((acc, selection) => {
  //       const markup = selection.selectedItems.reduce((acc, selectedItem) => {
  //         return (
  //           acc +
  //             (selectedItem.item?.price -
  //               selectedItem.item?.priceFoodieRestaurant) *
  //               selectedItem.count || 0
  //         );
  //       }, 0);
  //       return acc + markup;
  //     }, 0);
  //     return (
  //       acc + Math.max(dishMarkup - totalSelectionPrice, 0) + selectionMarkup
  //     );
  //   }, 0);
  //   return acc + markup;
  // }, 0);
  const markup = orders.reduce((acc, order) => {
    const {
      user,
      totalTaxMember,
      totalTaxNonmember,
      totalTaxRestaurant,
      subtotalMember,
      subtotalNonMember,
      subtotalRestaurant,
    } = order;
    if (user?.membership?.isActive) {
      return acc + (subtotalMember - subtotalRestaurant) || 0;
    } else {
      return acc + (subtotalNonMember - subtotalRestaurant) || 0;
    }
  }, 0);
  const discount =
    (orders[0]?.goods[0]?.dish?.restaurant?.commission || 0) / 100;

  let summary = {},
    totalItems = 0,
    total = 0,
    isPreOrdered = meal && meal.preOrderedDishes?.length > 0;
  LOGGING && console.log("CurrentList got:", meal?.preOrderedDishes);
  if (isPreOrdered) {
    meal.preOrderedDishes.forEach((good) => {
      const { quantity, soldQuantity, dish } = good;
      const { _id, name, priceFoodieListed } = dish;
      summary[name] = {
        dishId: _id,
        quantity: soldQuantity,
        preOrderedQuantity: quantity,
        pricePerItem: priceFoodieListed,
      };
    });
  } else {
    orders.forEach((order) => {
      const { goods } = order;
      LOGGING && console.log("CurrentList got goods:", goods);
      goods.forEach((good) => {
        const { dish, quantity, cancelQuantity = 0, price } = good;
        const key = dish.name;
        if (!summary[key]) {
          summary[key] = {
            dishId: dish._id,
            quantity: 0,
            price: good.price,
            priceFoodieListed: dish.priceFoodieListed,
          };
        }
        summary[key].quantity += quantity - cancelQuantity;
        totalItems += quantity - cancelQuantity;
        total +=
          (quantity - cancelQuantity) * (price || dish?.priceFoodieListed);
      });
    });

    if (orders.length > 0) {
      // add missing dish
      meal.dishe?.forEach((dish) => {
        if (!(dish.name in summary)) {
          summary[dish.name] = {
            dishId: dish._id,
            quantity: 0,
            priceFoodieListed: dish.priceFoodieListed,
          };
        }
      });
    }
  }

  return (
    <div className="current">
      <div className="meal-select-wrapper">
        <button
          className={`meal ${showMealType === "lunch" ? "selected" : ""}`}
          onClick={onSelectMeal.bind(this, "lunch")}
        >
          <span>{`lunch (${lunchOrders})`}</span>
        </button>
        <button
          className={`meal ${showMealType === "earlyDinner" ? "selected" : ""}`}
          onClick={onSelectMeal.bind(this, "earlyDinner")}
        >
          <span>{`express dinner (${earlyDinnerOrders})`}</span>
        </button>
        <button
          className={`meal ${showMealType === "dinner" ? "selected" : ""}`}
          onClick={onSelectMeal.bind(this, "dinner")}
        >
          <span>{`dinner (${dinnerOrders})`}</span>
        </button>
        <button
          className={`meal ${showMealType === "checkouts" ? "selected" : ""}`}
          onClick={onSelectMeal.bind(this, "checkouts")}
        >
          <span>{`checkouts (${checkouts.length})`}</span>
        </button>
        {/* <button
          className={`meal ${showMealType === "carts" ? "selected" : ""}`}
          onClick={onSelectMeal.bind(this, "carts")}
        >
          <span>{`carts (${carts.length})`}</span>
        </button> */}
        <button
          className={`meal ${
            showMealType === "canceledOrders" ? "selected" : ""
          }`}
          onClick={onSelectMeal.bind(this, "canceledOrders")}
        >
          <span>{`canceled orders (${canceledOrders.length})`}</span>
        </button>
      </div>

      {showMealType != "checkouts" &&
      showMealType != "carts" &&
      showMealType != "canceledOrders" ? (
        <div className="current">
          <div className="show-details-toggles">
            <button
              className={`show-details ${showDetails === 0 ? "selected" : ""}`}
              onClick={onShowDetails.bind(this, 0)}
            >
              details
            </button>
            <button
              className={`show-details ${showDetails === 1 ? "selected" : ""}`}
              onClick={onShowDetails.bind(this, 1)}
            >
              summary
            </button>
            <button
              className={`show-details ${showDetails === 2 ? "selected" : ""}`}
              onClick={onShowDetails.bind(this, 2)}
            >
              drivers
            </button>
          </div>

          <ChangeRestaurant
            restaurantList={restaurantList}
            onChangeRestaurant={onChangeRestaurant}
            selectedRestaurant={selectedRestaurant}
            showRestauntOptions={showRestaurantOptions}
            onToggleShowRestaurantOptions={onToggleShowRestaurantOptions}
          />

          {showDetails !== 1 ? null : (
            <Summary
              mealId={meal?._id}
              summary={summary}
              totalItems={totalItems}
              isPreOrdered={isPreOrdered}
              onSavePreOrderedDishQuantity={onSavePreOrderedDishQuantity}
              onCancelDish={onCancelDish}
              total={total}
              markup={markup}
              discount={discount}
            />
          )}
          {showDetails < 2 ? (
            <div
              className={`current-batch ${
                showDetails ? "show-details" : "show-summary"
              }`}
              ref={pdfRef}
            >
              <div className="order">
                {orders.length > 0 &&
                  (isEditRestaurant ? (
                    <div className="order-name restaurant edit">
                      <div className="field name">
                        <FontAwesomeIcon icon={faUtensilsAlt} />
                        <input
                          name="name"
                          value={formData.name}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field address">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <input
                          name="address"
                          value={formData.address}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field address">
                        <FontAwesomeIcon icon={faConciergeBell} />
                        <SelectForm
                          name="orderMethod"
                          value={formData.orderMethod}
                          options={OrderMethods}
                          onChange={onInputChange}
                        />
                      </div>
                      {Number(formData.orderMethod) === 1 && (
                        <div className="field address">
                          <FontAwesomeIcon icon={faClock} />
                          <EmailSchedule
                            emailSchedule={formData.emailSchedule}
                            onEmailScheduleChange={(emailSchedule) => {
                              updateFormData((formData) => {
                                formData.emailSchedule = emailSchedule;
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className="field contact-order-website">
                        <FontAwesomeIcon icon={faGlobe} />
                        <input
                          name="contact.orderWebsite"
                          value={formData.contact?.orderWebsite || ""}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field contact-order-notes">
                        <FontAwesomeIcon icon={faCommentAltLines} />
                        <input
                          name="contact.orderNotes"
                          value={formData.contact?.orderNotes || ""}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field contact-email">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <input
                          name="contact.email"
                          value={formData.contact?.email || ""}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field contact-name">
                        <FontAwesomeIcon icon={faUser} />
                        <input
                          name="contact.name"
                          value={formData.contact?.name || ""}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="field contact-mobile">
                        <FontAwesomeIcon icon={faSms} />
                        <input
                          name="contact.mobile"
                          value={formData.contact?.mobile || ""}
                          onChange={onInputChange}
                        />
                      </div>
                      <div className="action">
                        <Flex gap={12} justify="center" widthFull>
                          <Button
                            icon={faTimes}
                            variant="secondary"
                            onClick={() => {
                              setIsEditRestaurant(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            icon={faCheck}
                            onClick={() => {
                              onSaveRestaurant(formData);
                              setIsEditRestaurant(false);
                            }}
                          >
                            Save
                          </Button>
                        </Flex>
                      </div>
                    </div>
                  ) : (
                    <div className="order-name restaurant">
                      <div className="field name">
                        <FontAwesomeIcon icon={faUtensilsAlt} />
                        <span>{restaurant.name.split(",")[0]}</span>
                      </div>
                      <div className="field address">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span>{restaurant.address}</span>
                      </div>
                      <div className="field order-method">
                        <FontAwesomeIcon icon={faConciergeBell} />
                        <span>{`Order ${
                          OrderMethods[restaurant.orderMethod]
                        }`}</span>
                      </div>
                      {restaurant.orderMethod === 1 &&
                        restaurant.emailSchedule && (
                          <div className="field email-schedule">
                            <FontAwesomeIcon icon={faClock} />
                            <span>
                              Email Schedule: lunch (
                              {restaurant.emailSchedule.lunch.join(" ")}),
                              dinner (
                              {restaurant.emailSchedule.dinner.join(" ")})
                            </span>
                          </div>
                        )}
                      {restaurant.contact?.orderWebsite && (
                        <div className="field contact-order-website">
                          <FontAwesomeIcon icon={faGlobe} />
                          <span>{restaurant.contact.orderWebsite}</span>
                        </div>
                      )}
                      {restaurant.contact?.orderNotes && (
                        <div className="field contact-order-notes">
                          <FontAwesomeIcon icon={faCommentAltLines} />
                          <span>{restaurant.contact.orderNotes}</span>
                        </div>
                      )}
                      {restaurant.contact?.email && (
                        <div className="field contact-email">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <span>{restaurant.contact.email}</span>
                        </div>
                      )}
                      {restaurant.contact?.name && (
                        <div className="field contact-name">
                          <FontAwesomeIcon icon={faUser} />
                          <span>{restaurant.contact.name}</span>
                        </div>
                      )}
                      {restaurant.contact?.mobile && (
                        <div className="field contact-mobile">
                          <FontAwesomeIcon icon={faSms} />
                          <span>{PhoneFormat(restaurant.contact.mobile)}</span>
                        </div>
                      )}
                      <div className="action">
                        <Flex justify="center" widthFull>
                          <Button
                            icon={faEdit}
                            onClick={() => {
                              setFormData(restaurant);
                              setIsEditRestaurant(true);
                            }}
                          >
                            Edit
                          </Button>
                        </Flex>
                      </div>
                    </div>
                  ))}
              </div>

              <Email orders={orders} onEmail={onEmail} />

              {orders
                // .filter((o) => !o.orderTime)
                .map((order, i) => (
                  <Order
                    key={i}
                    order={order}
                    index={i}
                    total={orders.length}
                    onOrder={onOrder}
                    showDetails={showDetails}
                    onChangeOpsNote={onChangeOpsNote}
                    onEmail={onEmail}
                    onPayment={onPayment}
                    onCancelItem={onCancelItem}
                    onCancelOrder={onCancelOrder}
                  />
                ))}
            </div>
          ) : (
            <div className="current-batch drivers">
              <DriverRoutesSummaryContainer mealId={meal?._id} />
              {/* {Object.keys(drivers).map((driver) => (
                  <div className="route">
                    <div className="driver-name">{driver}</div>
                    <div className="customers">
                      {drivers[driver].map((customer) => (
                        <div className="customer">
                          <span className="stop">{customer.stop + 1}</span>
                          <span className="name">{customer.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))} */}
            </div>
          )}
        </div>
      ) : showMealType === "carts" ? (
        <CartList carts={carts} onFinishCart={onFinishCart} />
      ) : showMealType === "canceledOrders" ? (
        <CheckoutList
          checkouts={canceledOrders}
          onFinishCheckout={onResumeOrder}
          onFinishCheckoutNoPayment={onResumeOrderNoPayment}
          isResumeOrder={true}
        />
      ) : (
        <CheckoutList
          checkouts={checkouts}
          onFinishCheckout={onFinishCheckout}
          onFinishCheckoutNoPayment={onFinishCheckoutNoPayment}
          isResumeOrder={false}
        />
      )}
    </div>
  );
};

export default CurrentList;
