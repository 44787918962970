import React, { Component } from "react";
// import { confirmAlert } from 'react-confirm-alert';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getWeeklyScheduleKeyFromDayOfWeek } from "src/components/WeeklySchedule";
import { LOGGING, Loading, RestaurantDetails } from ".";
import {
  readRestaurantDetails,
  saveRestaurant,
  readRestaurantMeals,
  createRestaurantAccount,
  createCategory,
  updateCategory,
} from "../../store/actions";

class PageRestaurantDetails extends Component {
  constructor() {
    super();
    LOGGING && console.log("PageRestaurantDetails constructor");
    this.state = {
      loading: true,
      restaurant: null,
      meals: [],
      showOrderMethods: false,
      isEdittingRestaurantInfo: false,
    };

    this.handleCancelRestaurant = this.handleCancelRestaurant.bind(this);
    this.handleSaveRestaurant = this.handleSaveRestaurant.bind(this);
    this.handleEditRestaurant = this.handleEditRestaurant.bind(this);
    this.handleEditRestaurantContact =
      this.handleEditRestaurantContact.bind(this);
    this.handleToggleEdittingRestaurantInfo =
      this.handleToggleEdittingRestaurantInfo.bind(this);
    this.handleToggleShowOrderMethods =
      this.handleToggleShowOrderMethods.bind(this);
    this.handleSelectOrderMethod = this.handleSelectOrderMethod.bind(this);
    this.handleToggleShowLevels = this.handleToggleShowLevels.bind(this);
    this.handleSelectLevel = this.handleSelectLevel.bind(this);
    this.handleTogglePreorderOnly = this.handleTogglePreorderOnly.bind(this);
    this.handleToggleDisallowSpecialInstruction =
      this.handleToggleDisallowSpecialInstruction.bind(this);
    this.handleToggleHasKidsMenu = this.handleToggleHasKidsMenu.bind(this);
    this.handleToggleHasVeggieMenu = this.handleToggleHasVeggieMenu.bind(this);
    this.handleToggleScheduleChecked =
      this.handleToggleScheduleChecked.bind(this);
    this.handleToggleAllowLateCutoff =
      this.handleToggleAllowLateCutoff.bind(this);
    this.handleToggleHasMarkup = this.handleToggleHasMarkup.bind(this);
    this.handleToggleIsFoodieExclusive =
      this.handleToggleIsFoodieExclusive.bind(this);
    this.handleToggleDoordashDeliveryFee =
      this.handleToggleDoordashDeliveryFee.bind(this);
    this.handleToggleMayDelay = this.handleToggleMayDelay.bind(this);
    this.handleEmailScheduleChange = this.handleEmailScheduleChange.bind(this);
    this.handleEditCommission = this.handleEditCommission.bind(this);
    this.handleEditTaxRate = this.handleEditTaxRate.bind(this);
    this.handleEditMinOrderValue = this.handleEditMinOrderValue.bind(this);
    this.handleSetHeroImage = this.handleSetHeroImage.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
    this.handleSelectCuisines = this.handleSelectCuisines.bind(this);
    this.handleEditMaxOrders = this.handleEditMaxOrders.bind(this);
    this.handleEditFlatFee = this.handleEditFlatFee.bind(this);
    this.handleEditFeePerMile = this.handleEditFeePerMile.bind(this);
    this.handleEditWeeklyHours = this.handleEditWeeklyHours.bind(this);
    this.handleSaveNewCategory = this.handleSaveNewCategory.bind(this);
    this.handleToggleHeroImages = this.handleToggleHeroImages.bind(this);
    this.handleUpdateCategory = this.handleUpdateCategory.bind(this);
  }

  handleUpdateCategory({ name, priority, categoryId }) {
    const { restaurant } = this.state;
    this.props
      .updateCategory({
        restaurantId: restaurant?._id,
        name,
        priority,
        categoryId,
      })
      .then((categories) => {
        this.setState({ restaurant: { ...restaurant, categories } });
      });
  }
  handleSaveNewCategory(name) {
    LOGGING && console.log("handleSaveNewCategory called with:", name);
    const { restaurant } = this.state;
    this.props.createCategory(restaurant._id, name).then((categories) => {
      LOGGING &&
        console.log("handleSaveNewCategory got from server:", categories);
      this.setState({ restaurant: { ...restaurant, categories } });
    });
  }
  handleCreateAccount(restaurantId, e) {
    e.preventDefault();
    this.props.createRestaurantAccount(restaurantId);
  }

  handleEditCommission(e) {
    const { restaurant } = this.state;
    this.setState({
      restaurant: { ...restaurant, commission: e.target.value },
    });
  }

  handleEditTaxRate(e) {
    const { restaurant } = this.state;
    this.setState({ restaurant: { ...restaurant, taxRate: e.target.value } });
  }

  handleEditMinOrderValue(e) {
    const { restaurant } = this.state;
    this.setState({
      restaurant: { ...restaurant, minOrderValue: e.target.value },
    });
  }

  handleTogglePreorderOnly() {
    // e.preventDefault();
    const { restaurant } = this.state;
    const { preOrderOnly } = restaurant;
    LOGGING &&
      console.log(
        "handleTogglePreorderOnly called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: { ...restaurant, preOrderOnly: Boolean(!preOrderOnly) },
    });
  }
  handleToggleDisallowSpecialInstruction() {
    const { restaurant } = this.state;
    const { disallowSpecialInstruction } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleDisallowSpecialInstruction called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        disallowSpecialInstruction: Boolean(!disallowSpecialInstruction),
      },
    });
  }
  handleToggleHasKidsMenu() {
    const { restaurant } = this.state;
    const { hasKidsMenu } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleHasKidsMenu called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        hasKidsMenu: Boolean(!hasKidsMenu),
      },
    });
  }
  handleToggleHasVeggieMenu() {
    const { restaurant } = this.state;
    const { hasVeggieMenu } = restaurant;
    LOGGING &&
      console.log("handleHasVeggieMenu called with restaurant:", restaurant);
    this.setState({
      restaurant: {
        ...restaurant,
        hasVeggieMenu: Boolean(!hasVeggieMenu),
      },
    });
  }
  handleToggleScheduleChecked() {
    const { restaurant } = this.state;
    const { scheduleChecked } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleScheduleChecked called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        scheduleChecked: Boolean(!scheduleChecked),
      },
    });
  }
  handleToggleAllowLateCutoff() {
    const { restaurant } = this.state;
    const { allowLateCutoff } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleAllowLateCutoff called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        allowLateCutoff: Boolean(!allowLateCutoff),
      },
    });
  }
  handleToggleHasMarkup() {
    const { restaurant } = this.state;
    const { hasMarkup } = restaurant;
    LOGGING &&
      console.log("handleToggleHasMarkup called with restaurant:", restaurant);
    this.setState({
      restaurant: { ...restaurant, hasMarkup: Boolean(!hasMarkup) },
    });
  }
  handleToggleIsFoodieExclusive() {
    const { restaurant } = this.state;
    const { isFoodieExclusive } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleIsFoodieExclusive called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        isFoodieExclusive: Boolean(!isFoodieExclusive),
      },
    });
  }
  handleToggleDoordashDeliveryFee() {
    const { restaurant } = this.state;
    const { doordashDeliveryFee } = restaurant;
    LOGGING &&
      console.log(
        "handleToggleDoordashDeliveryFee called with restaurant:",
        restaurant
      );
    this.setState({
      restaurant: {
        ...restaurant,
        doordashDeliveryFee: {
          ...restaurant.doordashDeliveryFee,
          isFlatFee: Boolean(!doordashDeliveryFee?.isFlatFee),
        },
      },
    });
  }
  handleToggleMayDelay() {
    const { restaurant } = this.state;
    const { mayDelay } = restaurant;
    LOGGING &&
      console.log("handleToggleMayDelay called with restaurant:", restaurant);
    this.setState({
      restaurant: { ...restaurant, mayDelay: Boolean(!mayDelay) },
    });
  }
  handleSelectOrderMethod(orderMethod) {
    const { restaurant } = this.state;
    LOGGING &&
      console.log(
        "handleSelectOrderMethod called with:",
        orderMethod,
        restaurant
      );
    this.setState({
      showOrderMethods: false,
      restaurant: { ...restaurant, orderMethod },
    });
  }
  handleSelectLevel(level) {
    const { restaurant } = this.state;
    LOGGING && console.log("handleSelectLevel called with:", level, restaurant);
    this.setState({
      showLevels: false,
      restaurant: { ...restaurant, level },
    });
  }
  handleToggleShowOrderMethods(e) {
    e.preventDefault();
    const { showOrderMethods } = this.state;
    this.setState({ showOrderMethods: !showOrderMethods });
  }

  handleToggleShowLevels(e) {
    LOGGING && console.log("handleToggleShowLevels called");
    e.preventDefault();

    const { showLevels } = this.state;
    this.setState({ showLevels: !showLevels });
  }
  handleEditRestaurantContact(e) {
    const { restaurant } = this.state;
    const { contact } = restaurant;
    LOGGING &&
      console.log(
        "handleEditRestaurantContact called with:",
        e.target,
        contact
      );

    const updatedContact = {
      ...(contact || {}),
      [e.target.name]: e.target.value,
    };

    this.setState({
      restaurant: { ...restaurant, contact: { ...updatedContact } },
    });
  }

  handleEditRestaurant(e) {
    const { restaurant } = this.state;
    LOGGING &&
      console.log("handleEditRestaurant called with:", e.target, restaurant);

    this.setState({
      restaurant: { ...restaurant, [e.target.name]: e.target.value },
    });
  }

  handleEmailScheduleChange(emailSchedule) {
    const { restaurant } = this.state;
    this.setState({
      restaurant: { ...restaurant, emailSchedule },
    });
  }

  handleSaveRestaurant(e) {
    LOGGING && console.log("handleSaveRestaurant called.");
    e.preventDefault();
    const { restaurant } = this.state;
    LOGGING && console.log("handleSaveRestaurant called with:", restaurant);
    this.setState({ loading: true }, () => {
      this.props.saveRestaurant(restaurant).then(() => {
        // this.setState({
        //   loading: false,
        // });
        this.props.history.push("/restaurant-directory");
      });
    });
  }

  handleToggleEdittingRestaurantInfo(e) {
    e.preventDefault();
    const { isEdittingRestaurantInfo } = this.state;
    this.setState({ isEdittingRestaurantInfo: !isEdittingRestaurantInfo });
  }

  handleCancelRestaurant(e) {
    e.preventDefault();
    LOGGING && console.log("handleCancelRestaurant called");
    this.props.history.goBack();
  }

  handleSetHeroImage(restaurantId, heroImage) {
    LOGGING &&
      console.log("handleSetHeroImage called", { restaurantId, heroImage });
    const { restaurant } = this.state;
    const updatedRestaurant = { ...restaurant, heroImage };
    this.props.saveRestaurant(updatedRestaurant).then(() => {
      this.setState({ restaurant: updatedRestaurant });
    });
  }

  handleToggleHeroImages(restaurantId, heroImage) {
    LOGGING &&
      console.log("handleToggleHeroImages called", { restaurantId, heroImage });
    const { restaurant } = this.state;
    let { heroImages } = restaurant;
    let updatedHeroImages = heroImages.includes(heroImage)
      ? heroImages.filter((h) => h !== heroImage)
      : [...heroImages, heroImage];
    LOGGING &&
      console.log("handleToggleHeroImages got", {
        heroImages,
        updatedHeroImages,
      });

    const updatedRestaurant = {
      ...restaurant,
      heroImages: [...updatedHeroImages],
    };
    this.props.saveRestaurant(updatedRestaurant).then(() => {
      this.setState({ restaurant: updatedRestaurant });
    });
  }

  handleSelectCuisines(cuisines) {
    const { restaurant } = this.state;
    this.setState({ restaurant: { ...restaurant, cuisines: cuisines } });
  }

  handleEditMaxOrders(e) {
    const { restaurant } = this.state;
    this.setState({ restaurant: { ...restaurant, maxOrders: e.target.value } });
  }

  handleEditFlatFee(e) {
    const { restaurant } = this.state;
    this.setState({
      restaurant: {
        ...restaurant,
        doordashDeliveryFee: {
          ...restaurant.doordashDeliveryFee,
          flatFee: e.target.value,
        },
      },
    });
  }

  handleEditFeePerMile(e) {
    const { restaurant } = this.state;
    this.setState({
      restaurant: {
        ...restaurant,
        doordashDeliveryFee: {
          ...restaurant.doordashDeliveryFee,
          feePerMile: e.target.value,
        },
      },
    });
  }

  handleEditWeeklyHours(updatedWeeklyHoursFromForm) {
    const weeklySchedule = this.state.restaurant.weeklySchedule;
    weeklySchedule.forEach((scheduleForDay) => {
      const formKey = getWeeklyScheduleKeyFromDayOfWeek(
        scheduleForDay.dayOfWeek
      );
      scheduleForDay.lunch = updatedWeeklyHoursFromForm[formKey].lunch;
      scheduleForDay.earlyDinner =
        updatedWeeklyHoursFromForm[formKey].earlyDinner;
      scheduleForDay.dinner = updatedWeeklyHoursFromForm[formKey].dinner;
    });

    this.setState({
      restaurant: {
        ...this.state.restaurant,
        weeklySchedule: [...weeklySchedule],
      },
    });
  }

  componentDidMount() {
    LOGGING && console.log("PageRestaurantDetails componentdidmount");

    const { restaurantId } = this.props.match.params;
    if (restaurantId === "new") {
      this.setState({
        restaurant: {
          name: "",
          address: "",
          level: 0,
          contact: {},
          dishes: [],
          orderMethod: 0,
          websiteUrl: "",
          yelpUrl: "",
          instagramUrl: "",
          taxRate: "",
        },
        loading: false,
        isEdittingRestaurantInfo: true,
      });
    } else {
      this.props.readRestaurantDetails(restaurantId).then((restaurant) => {
        // this.props.readRestaurantMeals(restaurantId).then((meals) => {
        this.setState({ restaurant, loading: false }, () => {
          window.scrollTo(0, 0);
          // });
        });
      });
    }
  }

  render() {
    const { currentUser } = this.props;
    const {
      loading,
      restaurant,
      showOrderMethods,
      showLevels,
      isEdittingRestaurantInfo,
    } = this.state;

    LOGGING &&
      console.log("PageRestaurantDetails rendering with:", {
        props: this.props,
        state: this.state,
        isOps: currentUser && currentUser.user && currentUser.user.isOps,
      });

    if (
      !currentUser.isAuthenticated ||
      (!currentUser.user.isAdmin && !currentUser.user.isOps)
    ) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page">
        {loading ? (
          <Loading />
        ) : (
          <RestaurantDetails
            onEditRestaurant={this.handleEditRestaurant}
            onEditRestaurantContact={this.handleEditRestaurantContact}
            onSaveRestaurant={this.handleSaveRestaurant}
            onCreateAccount={this.handleCreateAccount}
            onClose={this.handleCancelRestaurant}
            restaurant={restaurant}
            meals={[]}
            isEdittingRestaurantInfo={isEdittingRestaurantInfo}
            onToggleEdittingRestaurantInfo={
              this.handleToggleEdittingRestaurantInfo
            }
            showOrderMethods={showOrderMethods}
            onToggleShowOrderMethods={this.handleToggleShowOrderMethods}
            onSelectOrderMethod={this.handleSelectOrderMethod}
            showLevels={showLevels}
            onToggleShowLevels={this.handleToggleShowLevels}
            onSelectLevel={this.handleSelectLevel}
            onTogglePreOrderOnly={this.handleTogglePreorderOnly}
            onToggleMayDelay={this.handleToggleMayDelay}
            onToggleDisallowSpecialInstruction={
              this.handleToggleDisallowSpecialInstruction
            }
            onToggleHasKidsMenu={this.handleToggleHasKidsMenu}
            onToggleHasVeggieMenu={this.handleToggleHasVeggieMenu}
            onToggleScheduleChecked={this.handleToggleScheduleChecked}
            onToggleAllowLateCutoff={this.handleToggleAllowLateCutoff}
            onToggleHasMarkup={this.handleToggleHasMarkup}
            onToggleIsFoodieExclusive={this.handleToggleIsFoodieExclusive}
            onToggleDoordashDeliveryFee={this.handleToggleDoordashDeliveryFee}
            onEmailScheduleChange={this.handleEmailScheduleChange}
            onEditCommission={this.handleEditCommission}
            onEditTaxRate={this.handleEditTaxRate}
            onEditMinOrderValue={this.handleEditMinOrderValue}
            onSetHeroImage={this.handleSetHeroImage}
            onToggleHeroImages={this.handleToggleHeroImages}
            onSelectCuisines={this.handleSelectCuisines}
            onEditMaxOrders={this.handleEditMaxOrders}
            onEditFlatFee={this.handleEditFlatFee}
            onEditFeePerMile={this.handleEditFeePerMile}
            onEditWeeklyHours={this.handleEditWeeklyHours}
            onSaveNewCategory={this.handleSaveNewCategory}
            onUpdateCategory={this.handleUpdateCategory}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageRestaurantDetails got redux state:", state);
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  saveRestaurant,
  readRestaurantDetails,
  readRestaurantMeals,
  createRestaurantAccount,
  createCategory,
  updateCategory,
})(PageRestaurantDetails);
