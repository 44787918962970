import * as Components from ".";

const Adamson = ({ onClick }) => {
  const link = "/meal/11-28-2024/lunch/adamson-s-french-dip";
  const mealId = "673a6f106fc76f0015dee9d7"; //thu lunch
  const windowStart = "5:00 PM";
  const image =
    "https://menuimages.chownowcdn.com/image-resizing?image=1622%2F3bf9b9f9-be53-4043-97bf-ff9c6c61346b.jpg&left=309&top=0&right=48&bottom=0&height=400&width=462&quality=85&fit=contain";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Adamson's French Dip
        </Components.RestaurantName>
        <Components.MenuName>Holiday Menu</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            <Components.Bullet></Components.Bullet>
            Pre-Order&nbsp;<b>by 7PM Monday 11/25</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>11:30-12:30PM Thu</b>&nbsp;Thanksgiving
          </Components.DeliveryTime>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Freshly cooked, while supplies last.
          </Components.Warning>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Holiday Prime Rib Roast</Components.DishName>
              <Components.AlaCartePrice>$89.95+</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Hand selected, boneless USDA Prime Rib slowly roasted in our Wood
              Fired Oven. 1/4 Roast serves four people, 1/2 Roast serves eight
              people, whole Roast serves 16 people.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Horseradish</Components.DishName>
              <Components.AlaCartePrice>$8.95+</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Au Jus</Components.DishName>
              <Components.AlaCartePrice>$9.95+</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Cole slaw</Components.DishName>
              <Components.AlaCartePrice>$9.95+</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>potato salad</Components.DishName>
              <Components.AlaCartePrice>$9.95+</Components.AlaCartePrice>
            </Components.AlaCarteName>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>

        <Components.PreOrderButton
          onClick={(e) => {
            e.preventDefault();
            onClick(link);
          }}
        >
          Pre-Order Now
        </Components.PreOrderButton>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Adamson;
