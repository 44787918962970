import { LOGGING } from "../../constants";
import { LOAD_HOLIDAY_MENUS } from "../actionTypes";
import { apiCall } from "../api";
export const setHoliday = (holiday) => ({
  type: LOAD_HOLIDAY_MENUS,
  payload: holiday,
});
export const readHolidayMenus = () => {
  return (dispatch, getState) => {
    return apiCall("GET", "/holidays/GetHoliday", {}, getState().currentUser)
      .then((holiday) => {
        LOGGING &&
          console.log("readHolidayMenus got holiday", holiday.meals.length);
        dispatch(setHoliday(holiday));
        return holiday;
      })
      .catch((err) => {
        LOGGING && console.log("readHolidayMenus got err", err);
      });
  };
};
