import * as Components from ".";

const BackAYard = ({ onClick }) => {
  const link = "/meal/11-27-2024/dinner/back-a-yard-grill-san-jose";
  const mealId = "673829df9872b900159f7b06";
  const image =
    "https://i.pinimg.com/1200x/81/09/a4/8109a4e59d5e2185e1f318cbf85052e2.jpg";
  const windowStart = "5:00 PM";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>Back A yard</Components.RestaurantName>
        <Components.MenuName>Thanksgiving Meals</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Only <b>3 turkeys left</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Wed</b>&nbsp;Thanksgiving Eve.
          </Components.DeliveryTime>
          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            hot or ready-to-serve.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          {/* <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Package one</Components.DishName>
              <Components.AlaCartePrice>$195</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              14-16 lb Jamaican-style juicy jerk turkey, with jerk pan gravy,
              jerk sauce, cranberry sauce, turkey dressing, rice and beans, herb
              roasted vegetables.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Package two</Components.DishName>
              <Components.AlaCartePrice>$195</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              14-16 lb maple-glazed roast turkey, with pan gravy, cranberry
              sauce, turkey dressing, candied yam, herb roasted vegetables.
            </Components.DishDescription>
          </Components.AlaCarteItem> */}
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Jamaican-style juicy jerk turkey
              </Components.DishName>
              <Components.AlaCartePrice>$105</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              14-16 lb Jamaican-style juicy jerk turkey, with jerk pan gravy,
              jerk sauce, and cranberry sauce.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                maple-glazed roast turkey
              </Components.DishName>
              <Components.AlaCartePrice>$105</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              14-16 lb maple-glazed roast turkey, with pan gravy and cranberry
              sauce.
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButton
          onClick={(e) => {
            e.preventDefault();
            onClick(link);
          }}
        >
          pre-order now
        </Components.PreOrderButton>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default BackAYard;
