import * as Components from ".";

const Maggio = ({ onClick }) => {
  const link = "/meal/11-28-2024/dinner/maggiano-s-little-italy";
  const linkCold = "/meal/11-27-2024/dinner/maggiano-s-little-italy";
  const mealId = "67382a549872b900159f7d7b"; // thu dinner
  const windowStart = "5:00 PM";
  const image =
    "https://restaurantscatering.com/wp-content/uploads/2024/08/Maggianos-little-Italy-Thanksgiving-Menu.png";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Maggiano's Little Italy
        </Components.RestaurantName>
        <Components.MenuName>Thanksgiving Family Style</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Pre-Order&nbsp;<b>by 8PM Wed 11/27</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Wed</b>&nbsp; (chilled)
          </Components.DeliveryTime>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Thu</b>&nbsp; (hot)
          </Components.DeliveryTime>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>hot family meal</Components.DishName>
              <Components.AlaCartePrice>$220</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              <b>First course</b>
              <br></br>Maggiano's salad, chef's siganture caesar salad.<br></br>
              <b>Second course</b>
              <br></br>Served with cranberry relish. Choose two from traditional
              roast turkey, country-style smoked ham, or salmon, lemon & herb.
              <br></br>
              <b>Two Pastas</b>
              <br></br>Choose from Taylor Street Baked Ziti | Our Famous
              Rigatoni “D”® | Spaghetti & Meatball Four-Cheese Ravioli |
              Butternut Squash Ravioli† | Rigatoni alla Vodka.<br></br>
              <b>Two Sides</b>
              <br></br>Choose from Tuscan Creamed Corn | Whipped Sweet Potatoes
              | Garlic Mashed Potatoes Roasted Garlic Broccoli | Sage & Sausage
              Stuffing.<br></br>
              <b>Third course</b>
              <br></br>Choose two from pumpkin praline cheesecake, warm apple
              crostada or chocolate layered cake.<br></br>
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkCold);
            }}
          >
            pre-order Wed Dinner
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order Thu Dinner
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Maggio;
