import React, { ReactElement, useCallback, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { RootState } from "src/store";
import styled from "styled-components";
import { NavByDayFormat, HeroImage } from "../..";
import { visit } from "../../../../../store/actions";
import {
  Centered,
  CenteredByColumn,
  actionBlack,
  text12300,
  BorderGrey,
  textTitle,
  card,
  centered,
  actionUnderline,
  actionTransparent,
} from "../../Shared";

export interface MealHighlightsProps {
  highlight: any;
}
export interface ContentContainerProps {
  // src: string;
}
export const HeroHeight = 30;
/* background-image: url(${(props) => props.src});*/
export const ContentContainer = styled(CenteredByColumn)<ContentContainerProps>`
  width: 100vw;
  height: ${HeroHeight}vh;
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  position: relative;
  .container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  @media only screen and (max-height: 600px) {
    height: 40vh;
  }
  @media only screen and (min-width: 1200px) {
    height: ${HeroHeight}vh;
    .container {
      .pic {
        height: 100%;
      }
    }
  }
`;
export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;
export const Content = styled(CenteredByColumn)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  color: white;
  padding: 20px;
  text-align: center;
  /* justify-content: flex-end; */
  padding-bottom: 60px;
`;
const Tagline = styled.span`
  ${textTitle}
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
`;
export const Title = styled(Centered)`
  span {
    ${text12300}
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
  }
  margin-bottom: 10px;
  @media only screen and (max-height: 600px) {
    span {
      font-size: 22px;
    }
  }
`;
export const RestaurantName = styled.span`
  ${text12300}
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 20px;
  @media only screen and (max-height: 600px) {
    margin-bottom: 10px;
  }
`;
const MealTime = styled.span`
  ${text12300}
`;
export const MealLink = styled.a`
  ${actionTransparent}
  z-index: 100;
  color: white;
  border-color: white;
  position: absolute;
  text-transform: uppercase;
  bottom: 30px;
  @media only screen and (max-height: 600px) {
    bottom: 20px;
  }
`;
const Price = styled.span`
  ${text12300}
  position: absolute;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid white;
  border-radius: 50%;
  top: 0px;
  right: 0px;
`;
export const MealHighlights: React.FC<MealHighlightsProps> = ({
  highlight,
}): ReactElement => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   console.log("MealHighlights rendered with:", highlight);
  //   dispatch(visit("competitor receipts"));
  // }, []);
  const {
    windowStart,
    restaurantName,
    mealType,
    title,
    heroImages,
    price,
    tagline,
    pathUrl,
  } = highlight;
  return (
    <ContentContainer>
      <Overlay></Overlay>
      {heroImages ? <HeroImage images={heroImages} /> : null}
      <Content>
        <RestaurantName>{`${restaurantName} ${mealType} ${moment(
          windowStart
        ).format(NavByDayFormat)}`}</RestaurantName>
        <Title>
          <span>{title}</span>
        </Title>
        <MealLink href={`https://foodie.earth/${pathUrl}`}>Order Now</MealLink>
      </Content>
    </ContentContainer>
  );
};
