import React, { ReactElement, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SerializedRestaurantDishSelection,
  SerializedRestaurantDishSelectionItem,
} from "src/api/meals";
import { DishSelection } from "src/components/DishSelectionModal/DishSelection";
import { Flex } from "src/components/Layout";
import { useForm } from "src/hooks";
import { RootState } from "src/store";
import styled from "styled-components";
import { DishChooserFactoryProps } from "./DishChooserFactory";
import { Good } from "./PureDishChooser";
import { PureDishChooser } from "./PureDishChooser";
import { LOGGING } from "..";
import { Prices } from "../customers/mealMenu/Prices";
import { Centered, CenteredByColumn, centered, text12300 } from "../Shared";

type NormalDishChooserProps = DishChooserFactoryProps;

type FormData = {
  selections: { [selectionId: string]: string[] }; //value is an array of selectionItemIds
  quantity: number;
  comment: string;
};
const DishSelections = styled(CenteredByColumn)`
  width: 100%;
`;
export const NormalDishChooser: React.FC<NormalDishChooserProps> = (
  props: NormalDishChooserProps
): ReactElement => {
  const {
    dish,
    isMember,
    isAuthenticated,
    countInCart,
    onAddGoodsToCart,
    onUpdateGoodInCart,
    onClickCountInCart,
    modalify,
    onStartMembership,
    bottom,
    quantityCap,
    isNotOrganizer,
    goodKey,
    good,
  } = props;
  const { priceFoodieListed, priceFoodieNonMember, priceDoordashListed } = dish;
  // LOGGING &&
  //   console.log("NormalDishChooser got:", {
  //     dish,
  //     goodKey,
  //     good,
  //   });

  // create default selection as an object with dishSelection as key and selectedItems as value
  // from selections array
  const defaultSelections = useMemo(() => {
    const result: { [selectionId: string]: string[] } = {};
    if (good) {
      good.selections.forEach(({ dishSelection, selectedItems }) => {
        result[dishSelection] = selectedItems.map(({ item }) => item);
      });
    }
    return result;
  }, [goodKey, dish]);

  const { formData, onInputChange, updateFormData } = useForm<FormData>({
    selections: defaultSelections,
    quantity: good?.quantity || 1, // or 0?
    comment: good?.[goodKey]?.comment || "",
  });

  const currentUser = useSelector((state: RootState) => state.currentUser);
  const userId = currentUser?.user?._id;
  const cache = useMemo(() => {
    const { selections } = dish;
    const result: {
      selections: { [selection: string]: SerializedRestaurantDishSelection };
      selectionItems: {
        [selectionItemId: string]: SerializedRestaurantDishSelectionItem;
      };
    } = {
      selections: {},
      selectionItems: {},
    };
    selections.forEach((selection) => {
      result.selections[selection._id] = selection;
      selection.items.forEach((selectionItem) => {
        result.selectionItems[selectionItem._id] = selectionItem;
      });
    });
    return result;
  }, [dish]);

  const isValid = useMemo(() => {
    let result = true;
    let requiredSelection = Object.values(cache?.selections)
      .filter((selection) => selection?.minSelection > 0)
      .map((selection) => selection?._id);
    Object.entries(formData?.selections).forEach(
      ([selectionId, selectionItemIds]) => {
        if (
          selectionItemIds.length >=
          cache?.selections?.[selectionId]?.minSelection
        ) {
          requiredSelection = requiredSelection.filter(
            (item) => item !== selectionId
          );
        }
      }
    );
    if (requiredSelection.length > 0) {
      result = false;
    }
    return result;
  }, [cache, formData]);

  const price = useMemo(() => {
    let result = dish.priceFoodieListed || 0;
    Object.values(formData.selections).forEach((selectionItemIds) => {
      selectionItemIds.forEach((selectionItemId) => {
        result += cache?.selectionItems[selectionItemId]?.price;
      });
    });
    return result;
  }, [dish, cache, formData]);

  const priceNonMember = useMemo(() => {
    let result = dish.priceDoordashListed || dish.priceFoodieNonMember || 0;
    Object.values(formData.selections).forEach((selectionItemIds) => {
      selectionItemIds.forEach((selectionItemId) => {
        result +=
          cache?.selectionItems[selectionItemId]?.priceDoordashListed ||
          cache?.selectionItems[selectionItemId]?.priceFoodieNonMember ||
          0;
      });
    });
    return result;
  }, [dish, cache, formData]);

  // LOGGING &&
  //   console.log("NormalDishChooser got: ", {
  //     dish,
  //     price,
  //     priceNonMember,
  //     isMember,
  //     modalify,
  //   });
  const confirm = useCallback(() => {
    const good: Good = {
      dish,
      addedBy: userId,
      selections: Object.entries(formData.selections).map(
        ([selectionId, selectionItemIds]) => {
          return {
            dishSelection: selectionId,
            selectedItems: selectionItemIds.map((selectionItemId) => ({
              item: selectionItemId,
              count: 1,
            })),
          };
        }
      ),
      price,
      priceNonMember,
      quantity: formData.quantity,
      comment: formData.comment,
    };
    onAddGoodsToCart([good]);
  }, [
    dish,
    formData.comment,
    formData.quantity,
    formData.selections,
    onAddGoodsToCart,
    price,
  ]);

  const confirmUpdate = useCallback(() => {
    const updatedGood: Good = {
      dish,
      addedBy: userId,
      selections: Object.entries(formData.selections).map(
        ([selectionId, selectionItemIds]) => {
          return {
            dishSelection: selectionId,
            selectedItems: selectionItemIds.map((selectionItemId) => ({
              item: selectionItemId,
              count: 1,
            })),
          };
        }
      ),
      price,
      priceNonMember,
      quantity: formData.quantity,
      comment: formData.comment,
    };
    LOGGING &&
      console.log("NormalDishChooser confirmUpdate called with", {
        goodKey,
        updatedGood,
      });
    onUpdateGoodInCart(goodKey, updatedGood);
  }, [
    dish,
    formData.comment,
    formData.quantity,
    formData.selections,
    onUpdateGoodInCart,
    price,
  ]);

  const changeDishQuantity = useCallback(
    (quantity) => {
      updateFormData((formData) => {
        formData.quantity = quantity;
      });
      LOGGING && console.log("NormalDishChooser changeDishQuantity called");
    },
    [updateFormData]
  );

  const history = useHistory();
  const defaultOnClickCountInCart = () => {
    history.push("/cart");
  };

  return (
    <PureDishChooser
      dish={dish}
      goodKey={goodKey}
      countInCart={countInCart}
      onClickCountInCart={onClickCountInCart ?? defaultOnClickCountInCart}
      modalify={modalify}
      price={price}
      priceNonMember={priceNonMember}
      isMember={isMember}
      isAuthenticated={isAuthenticated}
      onStartMembership={onStartMembership}
      // DishSelectionActionProps
      quantity={formData.quantity}
      onAddDishToCart={confirm}
      onUpdateGoodInCart={confirmUpdate}
      onChangeDishQuantity={changeDishQuantity}
      isValid={isValid}
      bottom={bottom}
      isNotOrganizer={isNotOrganizer}
      quantityCap={quantityCap}
    >
      <DishContent paddingBottom={modalify ? 100 : 50}>
        <DishName>
          <Flex direction="column" gap={12}>
            <span>{dish.name}</span>
          </Flex>
        </DishName>
        <Prices
          priceFoodieListed={priceFoodieListed}
          priceFoodieNonMember={priceFoodieNonMember}
          priceDoordashListed={priceDoordashListed}
          isMember={isMember}
        />
        {/* <DishPrice>
          <OnePrice theme={{ isSelected: isMember === false }}>
            <span>
              $
              {(
                dish.priceDoordashListed ||
                dish.priceFoodieNonMember ||
                0
              ).toFixed(2)}
            </span>
            <label>Regular</label>
          </OnePrice>
          <OnePrice theme={{ isSelected: isMember === true }}>
            <span>${(dish.priceFoodieListed || 0).toFixed(2)}</span>
            <label>Member</label>
          </OnePrice>
        </DishPrice> */}
        <DishDescription>
          <p>{dish.description}</p>
        </DishDescription>
        <DishSelections>
          {dish.selections.map((selection) => (
            <DishSelection
              key={selection._id}
              selectedItems={formData.selections[selection._id] || []}
              onChange={(selectedItems) => {
                LOGGING && console.log("NormalDishChooser onChange called");
                LOGGING &&
                  console.log(
                    "before: formData.selections[selection._id]",
                    formData.selections[selection._id]
                  );
                LOGGING && console.log("after: selectedItems", selectedItems);
                updateFormData((formData) => {
                  formData.selections[selection._id] = selectedItems;
                });
              }}
              selection={selection}
              isMember={isMember}
              onStartMembership={onStartMembership}
            />
          ))}
        </DishSelections>
        <DishComment>
          {!dish.restaurant.disallowSpecialInstruction ? (
            <Flex direction="column" gap={12}>
              <label>Special Requests (Optional)</label>
              <textarea
                name="comment"
                onChange={onInputChange}
                placeholder={"e.g. no onion, etc."}
                value={formData.comment}
              />
            </Flex>
          ) : (
            <Flex direction="column" gap={12}>
              <label>
                Special Requests (this restaurant does not take special
                requests)
              </label>
            </Flex>
          )}
        </DishComment>
      </DishContent>
    </PureDishChooser>
  );
};

type ContentProps = { paddingBottom: number };
export const DishContent = styled.div<ContentProps>`
  padding: 15px 25px 0 25px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  ${centered}
  flex-direction: column;
`;
const DishComment = styled.div`
  width: 100%;
  label {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    line-height: 13px;
    letter-spacing: 0.6px;
  }

  textarea {
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 90px;
    padding: 10px 15px;
    background: var(--background-grey);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.6px;
  }
`;

export const DishName = styled.div`
  span {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  margin-bottom: 15px;
`;

const DishPrice = styled(CenteredByColumn)`
  margin-bottom: 20px;
`;

const OnePrice = styled(Centered)`
  ${text12300}
  span {
    margin-right: 5px;
  }
  margin: 0px 10px;
  font-weight: ${(props) => (props.theme.isSelected ? 600 : 300)};
  /* color: ${(props) => (props.theme.isSelected ? "white" : "black")}; ; */
`;

export const DishDescription = styled.div`
  margin-top: 15px;
  p {
    margin: 0px;
    text-align: center;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.6px;
  }
`;
