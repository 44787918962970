import * as Components from ".";

const Beijing = ({ onClick }) => {
  const link = "/meal/11-28-2024/dinner/beijing-duck-house-";
  const linkLunch = "/meal/11-28-2024/lunch/beijing-duck-house-";
  const mealId = "67382a549872b900159f7d7f"; //thu dinner
  const windowStart = "5:00 PM";
  const image =
    "https://s3-media0.fl.yelpcdn.com/bphoto/O-tQQC1MF5o6w6CVu0Tnhw/o.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Beijing Duck House
        </Components.RestaurantName>
        <Components.MenuName>
          感恩节超值北京酥不腻烤鸭套餐<br></br>Thanksgiving Super Value Peking
          Duck Combos
        </Components.MenuName>
        <Components.Notes>
          {/* <Components.Warning>          
            <Components.Bullet></Components.Bullet>
            Only&nbsp;<b>2 ducks left</b>
          </Components.Warning> */}
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            <span>
              Receive&nbsp;<b>11:30-12:30P Thu</b>&nbsp;Thanksgiving.
            </span>
          </Components.DeliveryTime>
          <Components.Subnote>
            - Only <b>5 ducks left.</b>
          </Components.Subnote>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Thu</b>&nbsp;Thanksgiving.
          </Components.DeliveryTime>
          <Components.Subnote>
            - Ducks sold out, other dishes available.
          </Components.Subnote>
          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            Freshed cooked.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                酥不腻烤鸭套餐A（2-3人）
              </Components.DishName>
              <Components.AlaCartePrice>$55</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              酥不腻烤鸭半只，鸭饼12张，1份酱料，鸭汤，任选两个菜：宫保鸡丁，醋溜土豆丝，红烧豆腐，大盘鸡，木须肉，炒合菜。
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                酥不腻烤鸭套餐B（4-5人）
              </Components.DishName>
              <Components.AlaCartePrice>$108</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              酥不腻烤鸭1只，鸭饼24张，2份酱料，鸭汤，任选4个菜：宫保鸡丁，醋溜土豆丝，红烧豆腐，麻婆豆腐，大盘鸡，木须肉，炒时蔬，炒合菜。
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                酥不腻烤鸭套餐C（6-7人）
              </Components.DishName>
              <Components.AlaCartePrice>$160</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              酥不腻烤鸭1.5只，鸭饼24张，2份酱料，鸭汤，任选5个菜：小炒牛肉（辣），糖醋里脊，宫保鸡丁，醋溜土豆丝，红烧豆腐，麻婆豆腐，大盘鸡，木须肉，炒时蔬，炒合菜
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <span>
            only <b>5 ducks left</b>
          </span>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order thu lunch now
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
        <Components.PreOrderButtonWithNotesContainer>
          <span>ducks sold out</span>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order thu dinner now
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Beijing;
