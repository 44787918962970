import React, { ReactElement, useState } from "react";
import {
  faPlus,
  faMinus,
  faTrash,
  faTrophy as faTrendy,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch, useStore } from "react-redux";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { RootState } from "src/store";
import styled from "styled-components";
import { BestSeller, BestSellerReadOnly } from "./BestSeller";
import { DishListV2, DishListV2Props } from "./DishListV2";
import { MealHeader, MealHeaderProps } from "./MealHeader";
import { BreakPoint } from "./MealHeader";
import { OrderHistoryInMeal } from "./OrderHistoryInMeal";
import { Recommendation } from "./Recommendation";
import { LOGGING, Loading } from "../../";
import {
  Centered,
  card,
  TextTitle,
  CenteredByColumn,
  TextUPPER,
  Beige,
  Grey,
  text12300,
  Text12300,
  Yellow,
  CenteredByColumnLeft,
  Pink,
  Bronze,
  centered,
  ActionBlack,
} from "../../Shared";
import { MembershipDetailsCard } from "../banners/MemberSaving";

const PROMO_MEAL_MEALGROUP = 9;

type MealMenuScreenProps = DishListV2Props &
  MealHeaderProps & {
    cart?: any; // Only used in the edit order flow since the order in memory replaces the cart object
    // bestSellers?: [];
    restaurant?: any;
    isMobile?: boolean;
    isAuthenticated?: boolean;
    onShowAuth: () => void;
    mealId: string;
    mealGroup: number;
    promo?: any;
    windowStart: number;
    isExpress?: boolean;
    onShowGroupOrder?: () => void;
    groupOrderId?: string;
    name?: string;
    groupOrderLeader?: any;
    onOpenInvite?: () => void;
    isNotOrganizer?: boolean;
    distance?: number;
    addingDish?: string;
    loading?: boolean;
    onFlashCartPeek?: () => void;
    onAddGoodsToCart?: (goods) => void;
    onShowCartOnMeal?: () => void;
    fromHoliday?: boolean;
  };

const ContentContainer = styled.div`
  /* max-width: 960px; */
  width: 100%;
  padding: 0px;
  h1 {
    ${text12300}
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 14px 20px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
  }
`;
type TrendyContainerProps = { width: number };

const TrendyContainer = styled(CenteredByColumn)<TrendyContainerProps>`
  /* width: 100vw; */
  /* overflow: hidden; */
  align-items: flex-start;
  width: auto;
  padding-left: ${(props) => (props.width > 500 ? 60 : 0)}px;
`;
type TrendyTitleProps = { width: number };
const TrendyTitle = styled.span<TrendyTitleProps>`
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-align: left;
  font-size: 15px;
  /* font-weight: 700; */
  display: inline-block;
  margin: 0px 0px 14px ${(props) => (props.width > 500 ? 30 : 20)}px;
  font-weight: 700;
  white-space: nowrap;
  /* color: black; */
  /* font-weight: 400; */
  line-height: 0px;
  letter-spacing: 0.6px;
  font-size: 12px;
  padding-top: 20px;
  ${centered}
  justify-content: flex-start;
  svg {
    /* display: none; */
    font-size: 13px;
    margin-right: 5px;
    /* margin-top: 1px; */
  }
  /* background-color: ${Pink}; */
  @media only screen and (min-width: ${BreakPoint}px) {
    margin-left: 80px;
  }
`;
const TrendyList = styled(Centered)`
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
  /* margin-bottom: -8px; */
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  button:first-child {
    margin-left: 10px;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    padding-left: 70px;
  }
`;
const BestSellersContainer = styled(CenteredByColumnLeft)`
  /* width: 100%; */
  padding-left: 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
`;
const BestSellerTitle = styled(TextUPPER)`
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 16px;
  margin: 0px 0px 6px 0px;
`;
const BestSellerList = styled(Centered)`
  justify-content: flex-start;
  padding-left: 20px;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  margin-bottom: -20px;
  button:first-child {
    margin-left: 10px;
  }
`;
export const BestSellers = ({ trendy }) => (
  <>
    <BestSellerTitle>Best Sellers</BestSellerTitle>
    <BestSellerList>
      {trendy.map((dish, index) => {
        return <BestSellerReadOnly key={dish._id} dish={dish} index={index} />;
      })}
    </BestSellerList>
  </>
);
const Trendy = ({
  trendy,
  title,
  cart,
  width,
  onOpenDishDetails,
  onAddDishToCart,
  onRemoveDishFromCart,
  isMember,
  isAuthenticated,
  onShowAuth,
  restaurantIsMemberOnly,
  setShowDetails,
  addingDish,
}) => (
  <>
    <TrendyTitle width={width}>
      <FontAwesomeIcon icon={faTrendy} />
      {title}
    </TrendyTitle>
    <TrendyList>
      {trendy.map((dish, index) => {
        return (
          <BestSeller
            key={dish._id}
            cart={cart}
            dish={dish}
            index={index}
            isMember={isMember}
            onOpenDishDetails={onOpenDishDetails.bind(this, dish, 1)}
            onAddDishToCart={onAddDishToCart.bind(this, dish)}
            onRemoveDishFromCart={onRemoveDishFromCart.bind(this, dish)}
            isAuthenticated={isAuthenticated}
            onShowAuth={onShowAuth}
            restaurantIsMemberOnly={restaurantIsMemberOnly}
            setShowDetails={setShowDetails}
            isSaving={String(addingDish) === String(dish._id)}
          />
        );
      })}
    </TrendyList>
  </>
);
export const MealMenuScreen: React.FC<MealMenuScreenProps> = ({
  restaurant,
  // bestSellers,
  dishes,
  cart,
  loading,
  onOpenDishDetails,
  onAddDishToCart,
  onAddGoodsToCart,
  onRemoveDishFromCart,
  isMember,
  onStartMembership,
  isAuthenticated,
  onShowAuth,
  mealId,
  mealGroup,
  promo,
  windowStart,
  isMobile,
  isExpress,
  onShowGroupOrder,
  onOpenInvite,
  groupOrderId,
  name,
  groupOrderLeader,
  isNotOrganizer,
  distance,
  addingDish,
  onFlashCartPeek,
  onShowCartOnMeal,
  fromHoliday,
}: MealMenuScreenProps): ReactElement => {
  const orderHistoryByRestaurant = useSelector(
    (state: RootState) => state.orderHistoryByRestaurant
  );

  const { width } = useWindowDimensions();
  const { promoItems, _id } = restaurant;
  const dishIds = dishes.map((d) => d._id);
  const bestSellers =
    dishIds?.length > 0
      ? restaurant.bestSellers?.filter((d) => dishIds.includes(d._id))
      : restaurant.bestSellers;
  const orderHistory = orderHistoryByRestaurant[_id];
  const [showDetails, setShowDetails] = useState(false);

  // LOGGING &&
  //   console.log("MealMenuScreen rendering with: ", {
  //     bestSellers,
  //     restaurant,
  //   });
  return (
    <ContentContainer>
      <MealHeader
        restaurant={restaurant}
        onShowJoin={setShowDetails.bind(this, true)}
        isMember={isMember}
        isAuthenticated={isAuthenticated}
        isMobile={isMobile}
        isExpress={isExpress}
        onShowGroupOrder={onShowGroupOrder}
        groupOrderId={groupOrderId}
        name={name}
        groupOrderLeader={groupOrderLeader}
        onOpenInvite={onOpenInvite}
        isNotOrganizer={isNotOrganizer}
        distance={distance}
      />
      {showDetails ? (
        <MembershipDetailsCard
          saving={cart?.saving}
          card={{}}
          isAuthenticated={isAuthenticated}
          wasMember={false}
          onStartMembership={onStartMembership.bind(
            this,
            "MembershipDetailsCard"
          )}
          onResumeMembership={{}}
          onHideCard={setShowDetails.bind(this, false)}
        />
      ) : null}
      {/* <Recommendation
        restauarantId={restaurant?._id}
        onAddGoodsToCart={onAddGoodsToCart}
        isMember={isMember}
        onShowCartOnMeal={onShowCartOnMeal}
      /> */}
      {orderHistory && orderHistory.length > 0 ? (
        <OrderHistoryInMeal
          orders={orderHistory}
          mealId={mealId}
          windowStart={windowStart}
          onFlashCartPeek={onFlashCartPeek}
        />
      ) : null}
      {bestSellers &&
      bestSellers.length > 0 &&
      mealGroup != PROMO_MEAL_MEALGROUP &&
      !fromHoliday ? (
        <Trendy
          width={width}
          trendy={bestSellers}
          title={"Best Sellers"}
          onOpenDishDetails={onOpenDishDetails}
          cart={cart}
          onAddDishToCart={onAddDishToCart}
          onRemoveDishFromCart={onRemoveDishFromCart}
          isMember={isMember}
          isAuthenticated={isAuthenticated}
          onShowAuth={onShowAuth}
          restaurantIsMemberOnly={restaurant.memberOnly}
          setShowDetails={setShowDetails.bind(this, true)}
          addingDish={addingDish}
        />
      ) : null}
      {promoItems &&
      promoItems.length > 0 &&
      mealGroup === PROMO_MEAL_MEALGROUP ? (
        <Trendy
          width={width}
          trendy={promoItems}
          title={`Get ${promo.amount} Free on $${promo.minTotal}+`}
          onOpenDishDetails={onOpenDishDetails}
          cart={cart}
          onAddDishToCart={onAddDishToCart}
          onRemoveDishFromCart={onRemoveDishFromCart}
          isMember={isMember}
          isAuthenticated={isAuthenticated}
          onShowAuth={onShowAuth}
          restaurantIsMemberOnly={restaurant.memberOnly}
          setShowDetails={setShowDetails.bind(this, true)}
          addingDish={addingDish}
        />
      ) : null}
      {loading ? (
        <Loading />
      ) : (
        <DishListV2
          cart={cart}
          dishes={dishes}
          onOpenDishDetails={onOpenDishDetails}
          onAddDishToCart={onAddDishToCart}
          onRemoveDishFromCart={onRemoveDishFromCart}
          isMember={isMember}
          onStartMembership={onStartMembership}
          restaurantIsMemberOnly={restaurant.memberOnly}
          isAuthenticated={isAuthenticated}
          onShowAuth={onShowAuth}
          onShowMembershipDetails={setShowDetails.bind(this, true)}
          addingDish={addingDish}
        />
      )}
    </ContentContainer>
  );
};
