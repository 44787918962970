import { useSelector } from "react-redux";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { Container, Field, Title } from "./Contact";
import { FoodieMember } from "./Fees";
import { NavHeight, Yellow } from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";
const SavingTitle = styled(Title)`
  display: none;
  @media screen and (max-width: ${BreakPoint}px) {
    display: flex;
  }
`;
const SavingContainer = styled(Container)`
  /* border: none; */
  /* background-color: ${Yellow}; */
  > div {
    /* justify-content: flex-start; */
    b {
      display: inline-block;
      margin: 0px 3px;
    }
  }
  @media screen and (max-width: ${BreakPoint}px) {
    /* padding: 0px 50px;
    position: fixed;
    top: 0px;
    z-index: 3;
    text-align: center;
    border: none;
    span {
      line-height: 1.6 !important;
      font-size: 15px !important;
    }
    height: ${NavHeight}px; */
  }
`;
export const BenefitsComparedToDoordash = () => {
  const cart = useSelector((state: RootState) => state?.cart?.payLoad);
  const { memberSaving, isOutOfRange, isAvailable, distance } =
    cart?.doordashComparables || {};
  const saving = memberSaving?.totalSaving;
  return (
    <SavingContainer>
      <SavingTitle>summary</SavingTitle>
      <Field>
        {!isAvailable ? (
          <>
            <span>
              Restaurant is only available to <b>Foodie Members</b>.
            </span>
            <FoodieMember />
          </>
        ) : isOutOfRange && distance > 0 ? (
          <span>
            This restaurant is <b>{distance?.toFixed(1)} mi</b> away, out of
            range on Doordash.
          </span>
        ) : (
          <span>
            You saved <b>${saving?.toFixed(2)}</b> compared to ordering on
            Doordash.
          </span>
        )}
      </Field>
    </SavingContainer>
  );
};
