import {
  faTruck,
  faExclamationTriangle,
  faHeat,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Components from ".";

const Urban = ({ onClick }) => {
  const link = "/meal/11-27-2024/dinner/urban-plates-san-jose";
  const mealId = "67382a1e9872b900159f7cca";
  const windowStart = "5:00 PM";
  const image =
    "https://i.pinimg.com/1200x/2c/87/36/2c8736c55e2bf6d123920095787458d2.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>Urban Plate</Components.RestaurantName>
        <Components.MenuName>Thanksgiving Packages</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Pre-Order&nbsp;<b>by 6:30PM Sunday 11/24</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Wed</b>&nbsp;Thanksgiving Eve.
          </Components.DeliveryTime>
          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            Chilled or hot.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Turkey family feast (chilled)
              </Components.DishName>
              <Components.AlaCartePrice>$174.95</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Includes antibiotic and hormone free herb-roasted cage-free turkey
              breast, mushroom stuffing, gravy, cranberry relish, sliced artisan
              bread, choice of two feast sides. Fully cooked and chilled, with
              heating instructions.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Chilled feast sides</Components.DishName>
              <Components.AlaCartePrice>$29.95</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Your choice of roasted brussels sprouts, sweet potatoes, mashed
              potatoes, mac & cheese, or green beans. Serves 6-8.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Turkey family meal (hot)
              </Components.DishName>
              <Components.AlaCartePrice>$99.95</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Includes herb-roasted turkey breast, mushroom stuffing, roassted
              brussels sprouts, mashed potatoes, gravy, cranberry relish, rustic
              bread. Serves 4, hot and ready to eat.
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>

        <Components.PreOrderButton
          onClick={(e) => {
            e.preventDefault();
            onClick(link);
          }}
        >
          pre-order now
        </Components.PreOrderButton>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Urban;
